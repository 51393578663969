import mintingBG from "../assets/images/backdrop-minting.png";
import { useState } from "react";
import { Contract, ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isCommunityResourcable } from "@ethersproject/providers";
import { useEffect } from "react";
import { useInsertionEffect } from "react";

function Minting() {
  // const network = 25; //137;
  const network = 1;
  const decimal = 18;
  const { ethereum } = window;
  const contractAddress = "0xF7B78c9a4AF2018c9500DFBf91Bb873B6cB420b8";
  const [link, setLink] = useState("");
  const [nft, setNft] = useState("");
  const [nftAmt, setNftAmt] = useState(0);
  const abi = [
    {
      inputs: [
        { internalType: "string", name: "_name", type: "string" },
        { internalType: "string", name: "_symbol", type: "string" },
        { internalType: "uint8", name: "decimals", type: "uint8" },
        { internalType: "uint8", name: "decimals", type: "uint8" },
        { internalType: "uint256", name: "_mintPrice", type: "uint256" },
        { internalType: "uint256", name: "_maxNFT", type: "uint256" },
        {
          internalType: "uint256",
          name: "_whitelistMintPrice",
          type: "uint256",
        },
      ],
      stateMutability: "payable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "approved",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "ApprovalForAll",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "Paused",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "Unpaused",
      type: "event",
    },
    {
      inputs: [
        { internalType: "address", name: "tokenAddress", type: "address" },
        { internalType: "uint256", name: "tokenAmount", type: "uint256" },
      ],
      name: "WithdrawTokens",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address[]", name: "_addresses", type: "address[]" },
      ],
      name: "addBatchToWhitelisted",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "_address", type: "address" }],
      name: "addToWhitelisted",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "approve",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "baseURI",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "burn",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "getApproved",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "operator", type: "address" },
      ],
      name: "isApprovedForAll",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "maxNFT",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "mintNFT",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "mintPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "mintStarted",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "ownerMintNFT",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "ownerOf",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "pause",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "paused",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "_address", type: "address" }],
      name: "removeFromWhitelisted",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
        { internalType: "bytes", name: "_data", type: "bytes" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "operator", type: "address" },
        { internalType: "bool", name: "approved", type: "bool" },
      ],
      name: "setApprovalForAll",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "string", name: "_baseURI", type: "string" }],
      name: "setBaseURI",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_mintPrice", type: "uint256" },
      ],
      name: "setMintPrice",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
      name: "supportsInterface",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "toggleMint",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
      name: "tokenByIndex",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "uint256", name: "index", type: "uint256" },
      ],
      name: "tokenOfOwnerByIndex",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "tokenURI",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "unpause",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "whitelistMintPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "whitelistedAddress",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "decimals",
      outputs: [
        {
          internalType: "uint8",
          name: "",
          type: "uint8",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "withdrawCoins",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ];
  // const options = {
  //   loop: true,
  //   margin: 10,
  //   dot: false,
  //   nav: true,
  //   autoplay: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 2,
  //     },
  //     768: {
  //       items: 2,
  //     },
  //     992: {
  //       items: 3,
  //     },
  //     1024: {
  //       items: 4,
  //     },
  //   },
  // };
  // const optionsTwo = {
  //   loop: true,
  //   margin: 10,
  //   dot: false,
  //   nav: true,
  //   autoplay: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 2,
  //     },
  //     768: {
  //       items: 2,
  //     },
  //     992: {
  //       items: 3,
  //     },
  //     1024: {
  //       items: 4,
  //     },
  //   },
  // };
  const handlenft = (event) => {
    let nft = parseInt(event.target.value);
    if (Number.isInteger(nft)) {
      setNft(nft);
      setNftAmt(nft);
    } else if (!nft) {
        toast.warn(
            <div>
              Enter NFT Amount
            </div>,
            { autoClose: 3000 }
          );
    //   alert(" Enetr NFT Amount");
      setNft("");
    } else {
        toast.warn(
            <div>
              Enter NFT Amount
            </div>,
            { autoClose: 3000 }
          );
    }
  };

  const [Contract, setContract] = useState([]);
  const [totalSupply, setTotalSupply] = useState();
  const init = async () => {
    //setContract(contract)
  };
  useEffect(() => {
    //init()
  }, []);
  useEffect(() => {
    const counter = async () => {
      let provider = new ethers.providers.Web3Provider(ethereum);
      const contract = new ethers.Contract(contractAddress, abi, provider);
      let totalSupply = await contract.totalSupply();
      totalSupply = parseInt(totalSupply);
      setTotalSupply(totalSupply);
      //console.log("totalSupply",totalSupply)
    };
    counter();
   
  });
  // Calculating Mint
  const mintNFT = async () => {
    try {
      if (ethereum != undefined) {
        if (nftAmt > 0 && nftAmt <= 5) {
          let provider = new ethers.providers.Web3Provider(ethereum);
          let account = await provider.send("eth_requestAccounts", []);
          let signer = provider.getSigner();
          let pfContract = new ethers.Contract(contractAddress, abi, signer);
          pfContract.attach(account[0]);
          pfContract.connect(signer);
          let mintPrice = await pfContract.mintPrice();
          let amount = nftAmt;
          let mintPriceEth = ethers.utils.formatEther(mintPrice);
          mintPriceEth = parseFloat(mintPriceEth) * amount;
          mintPriceEth = mintPriceEth * Math.pow(10, 18);
          mintPrice = mintPriceEth.toString();
          let balance = await provider.getBalance(account[0]);
          if (balance > amount) {
            let transaction = await pfContract.mintNFT(amount, {
              value: mintPrice,
            });
            const transactionhash = transaction.hash;
            const Link = " https://etherscan.io/tx/" + transactionhash;
            console.log("transaction", transactionhash);
            toast.info(<div> Wait For the Transaction</div>, {
              autoClose: 5000,
            });
            await transaction.wait();
            toast.success(
              <div>
                Hey 👋, NFT Minting is completed. Check details here
                <a href={Link} target="_blank">
                  <button
                    style={{
                      padding: "15px",
                      color: "white",
                      backgroundColor: "red",
                      border: "none",
                    }}
                  >
                    View Transaction Details
                  </button>
                </a>
              </div>,
              { autoClose: false }
            );
            setNft("");
          } else {
            toast.warn(
              <div>
                Hey 👋, You don`t have sufficient balance for Minting the Freak NFTs.
              </div>,
              { autoClose: 5000 }
            );
          } // window.location.reload();
        } else if (nftAmt > 5) {
            toast.warn(
                <div>
                  You can't mint more than 5 NFTs.
                </div>,
                { autoClose: 3000 }
              );
          //alert("You can't mint more than 5 NFTs.");
        } else {
            toast.warn(
                <div>
                  Please enter NFT amount.
                </div>,
                { autoClose: 3000 }
              );
          //alert("Enter NFT Amount");
        }
      } else {
        toast.error(
            <div>
              Install Metamask
            </div>,
            { autoClose: 3000 }
          );
        //alert("Install Metamask");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="minting" id="mint">
      <ToastContainer />
      <div className="minting__content">
        <img src={mintingBG} alt="" />
        <div className="minting__wrapper">
          <div className="minting__head">
            <h2>Mint it</h2>
            <p>You can mint max 5 NFTs</p>
          </div>
          <div className="minting__input">
            <input
              type="number"
              name=""
              id=""
              placeholder="0"
              value={nft}
              onChange={(event) => {
                handlenft(event);
              }}
            />
            <span>{totalSupply} / 5000</span>
          </div>
          <button className="minting__button" onClick={mintNFT}>
            Mint
          </button>
        </div>
      </div>
    </div>
  );
}

export default Minting;
