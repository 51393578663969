import { collectionItemArray } from "../utils/collectionContent";

function Collection() {
    return (
        <div className='collection' id='collections'>
            <h2 className='collection__heading'>Collections</h2>
            <div className='collection__item'>
                {collectionItemArray.map((collectionType, index) => {
                    return (
                        <div key={index} className={`collection__item__box collection__item__${collectionType.colorName}`} style={{ backgroundColor: collectionType.colorValue }}>
                            <div className='collection__item__image'>
                                <img src={collectionType.imgSrc} alt={`collection ${collectionType.colorName}`} />
                            </div>
                        </div>
                    );

                })}
            </div>
        </div>
    );
}

export default Collection;
