import communityImage1 from '../assets/images/bg-image-comm-1.jpg';
import communityImage2 from '../assets/images/bg-image-comm-2.jpg';
import communityImage3 from '../assets/images/bg-image-comm-3.jpg';
import communityImage1hd from '../assets/images/bg-image-comm-1-hd.jpg';
import greenFreak from '../assets/images/collection04.png';
import greenFreakFlip from '../assets/images/collection04-flip.png';
import orangeFreak from '../assets/images/orange-freak.png';
import orangeFreakFlip from '../assets/images/orange-freak-flip.png';

import fire from '../assets/svg/fire.svg';

import Header from "../components/header";
import Section from '../components/section';
import ContentBlock from "../components/content-block";
import Footer from '../components/footer';

import React, { useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

function Community() {
    document.body.classList.add('community-page');

    const communityOneRef = React.useRef(null);
    const communityTwoRef = React.useRef(null);
    const communityOneHeightRef = communityOneRef?.current?.clientHeight;
    const communityTwoHeightRef = communityTwoRef?.current?.clientHeight;
    const windowWidth = document.documentElement.clientWidth;
    const [docWidth, setDocWidth] = React.useState(windowWidth);
    const [commOneHeight, setCommOneHeight] = React.useState(communityOneHeightRef);
    const [commTwoHeight, setCommTwoHeight] = React.useState(communityTwoHeightRef);
    const [scrollPos, setScrollPos] = React.useState(document.scrollingElement.scrollHeight);
    const [scrollUp, setScrollUp] = React.useState(false);

    React.useEffect(() => {
        function updateSize() {
            setDocWidth([windowWidth]);
            setCommOneHeight([communityOneHeightRef]);
            setCommTwoHeight([communityTwoHeightRef]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [windowWidth, communityOneHeightRef, communityTwoHeightRef]);
    document.documentElement.style.setProperty('--commOneHeight', `${commOneHeight}px`)
    document.documentElement.style.setProperty('--commTwoHeight', `${commTwoHeight}px`)
    document.documentElement.style.setProperty('--windowSize', `${docWidth}px`)

    const handleScroll = useCallback((e) => {
        if (scrollPos > window.scrollY) {
            setScrollUp(true);
        } else if (scrollPos < window.scrollY) {
            setScrollUp(false);
        }
        setScrollPos(window.scrollY);
    }, [scrollPos])

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);
    return (
        <div className='content'>
            <Header />
            <div className='community'>
                {windowWidth >= 1921 ?
                    <Section id={'community-one'} ref={communityOneRef} imgSrc={communityImage1hd} />
                    :
                    <Section id={'community-one'} ref={communityOneRef} imgSrc={communityImage1}>
                        <ParallaxProvider>
                            <div className='fire-svg'>
                                <ReactSVG src={fire} />
                            </div>
                            {scrollUp ?
                                <>
                                    <Parallax className='green-freak up' speed={20} translateY={[0, 0]} translateX={[0, 300]}>
                                        <div>
                                            <img src={greenFreakFlip} alt="" />
                                        </div>
                                    </Parallax>
                                    <Parallax className='orange-freak up' speed={20} translateY={[0, 0]} translateX={[0, -300]}>
                                        <div>
                                            <img src={orangeFreakFlip} alt="" />
                                        </div>
                                    </Parallax>
                                </>
                                :
                                <>
                                    <Parallax className='green-freak' speed={20} translateY={[0, 0]} translateX={[0, 300]}>
                                        <div>
                                            <img src={greenFreak} alt="" />
                                        </div>
                                    </Parallax>
                                    <Parallax className='orange-freak' speed={20} translateY={[0, 0]} translateX={[0, -300]}>
                                        <div>
                                            <img src={orangeFreak} alt="" />
                                        </div>
                                    </Parallax>
                                </>
                            }

                        </ParallaxProvider>
                    </Section>
                }

                <Section id={'community-two'} imgSrc={communityImage2}>
                    <ContentBlock
                        text={`Here in this place the magic of the freaks happens.`}
                        alignment={'center'}
                        heading={'What is this place?'}
                        ref={communityTwoRef}
                    >
                        <p>Here you vote how the project should develop and what other changes we should make.</p>
                        <div>
                            <p>This is our community space that only NFT holders can visit.</p>
                            <p>So you can feel honored to be here.</p>
                        </div>
                    </ContentBlock>
                </Section>
                <Section id={'community-three'} imgSrc={communityImage3}>
                    <ContentBlock alignment={'center'} heading={'What is the current vote about.'} />
                    <ContentBlock alignment={'center'} heading={'Coming Soon ...'} className='dark' />
                </Section>
                <Footer />
            </div>
        </div>
    )
};

export default Community;
