import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

export function ImageObject(props) {
    return (
        <div className={`object__${props.name}${props.className ? ` ${props.className}` : ''}`}>
            <img src={props.imgSrc} alt='' />
        </div>
    );
}

export function Butterfly(props) {
    return (
        <ParallaxProvider>
            <div className={`object__${props.name}${props.className ? ` ${props.className}` : ''}`}>
                <Parallax speed={-15} translateY={[-50, 80]}>
                    <img src={props.imgSrc} alt='' />
                </Parallax>
            </div>
        </ParallaxProvider>
    );
}