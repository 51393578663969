import { forwardRef } from "react";

const Section = forwardRef((props, ref) => (
    <>
        <div id={props.id} className={`section__${props.id}`}>
            {props.imgSrc ?
                <div ref={ref} className={`section__${props.id}__image-bg`}>
                    <img src={props.imgSrc} alt="" />
                </div> : <></>
            }
            {props.children}
        </div>
    </>
));

export default Section;