import colImageOne from '../assets/images/collection01.png';
import colImageTwo from '../assets/images/collection02.png';
import colImageThree from '../assets/images/collection03.png';
import colImageFour from '../assets/images/collection04.png';
import colImageFive from '../assets/images/collection05.png';
import colImageSix from '../assets/images/collection06.png';
import colImageSeven from '../assets/images/collection07.png';
import colImageEight from '../assets/images/collection08.png';

export const collectionItemArray = [
    { colorName: 'sky', colorValue: '#00CCFF', imgSrc: colImageOne },
    { colorName: 'golden', colorValue: '#FFDE2F', imgSrc: colImageTwo },
    { colorName: 'salmon', colorValue: '#FF809B', imgSrc: colImageThree },
    { colorName: 'daffodil', colorValue: '#F9F18C', imgSrc: colImageFour },
    { colorName: 'candy', colorValue: '#FFB8B8', imgSrc: colImageFive },
    { colorName: 'daffodil-two', colorValue: '#F9F18C', imgSrc: colImageSix },
    { colorName: 'garden', colorValue: '#BBE049', imgSrc: colImageSeven },
    { colorName: 'nazar', colorValue: '#4DD4DB', imgSrc: colImageEight }
]