import box20 from '../assets/svg/box-20.svg'
import box50 from '../assets/svg/box-50.svg'
import box70 from '../assets/svg/box-70.svg'
import box100 from '../assets/svg/box-100.svg'
import timelineImg from '../assets/images/timeline.png'
import timelineFreak from '../assets/images/timeline-freak.png'

import React from 'react';

function Roadmap() {
    const windowWidth = document.documentElement.clientWidth;

    const roadmapRef = React.useRef(null);
    const roadmapHeightRef = roadmapRef?.current?.getBoundingClientRect()?.height;

    const [docWidth, setDocWidth] = React.useState(windowWidth);
    const [roadmapHeight, setRoadmapHeight] = React.useState(roadmapHeightRef);
    const [isRoadmap, setIsRoadmap] = React.useState(false);

    React.useEffect(() => {
        function updateSize() {
            setDocWidth([windowWidth]);
            setRoadmapHeight([roadmapHeightRef]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [windowWidth, roadmapHeightRef]);
    document.documentElement.style.setProperty('--windowSize', `${docWidth}px`)
    document.documentElement.style.setProperty('--roadmapHeight', `${roadmapHeight}px`)

    window.onscroll = () => {
        setRoadmapHeight([roadmapHeightRef]);
        const roadmapDiv = roadmapRef?.current?.getBoundingClientRect();
        if (roadmapDiv.bottom > 0 && roadmapDiv.bottom <= roadmapDiv.height) {
            setIsRoadmap(true);
        } else {
            setIsRoadmap(false);
        };
        const freakPosition = roadmapDiv.bottom - 200;
        if (isRoadmap) {
            if (roadmapDiv.bottom <= roadmapDiv.height) {
                document.documentElement.style.setProperty('--freakPosition', `${freakPosition.toFixed(0)}px`);
            }
            if (freakPosition.toFixed(0) < 50 || freakPosition.toFixed(0) < 0) {
                document.documentElement.style.setProperty('--freakPosition', `50px`);
            }
        }

    }


    return (
        <div className='roadmap' id='roadmap' ref={roadmapRef}>
            <h2 className='roadmap__heading'>Mint Road Map</h2>
            {windowWidth >= 1025 ?
                <div className='desktop'>
                    <div className='roadmap__left'>
                        <div className='roadmap__item roadmap__item__50'>
                            <h3>50%</h3>
                            <div className='roadmap__item__object'>
                                <img src={box50} alt="" />
                                <p>It's time to give back! 5 NFT holders win a trip for 10 days to anywhere they want.</p>
                            </div>
                        </div>
                        <div className='roadmap__item roadmap__item__100'>
                            <h3>100%</h3>
                            <div className='roadmap__item__object'>
                                <img src={box100} alt="" />
                                <p>Mint is over. Roadmap 2 will be released! We have enough resources to plan big things and you help us with your votes!</p>
                            </div>
                        </div>
                    </div>
                    <div className='roadmap__timeline'>
                        <div className='roadmap__timeline__image'>
                            <img src={timelineImg} alt="" />
                            <div className={`roadmap__timeline__freak${isRoadmap ? ' shown' : ''}`}>
                                <img src={timelineFreak} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className='roadmap__right'>
                        <div className='roadmap__item roadmap__item__20'>
                            <h3>20%</h3>
                            <div className='roadmap__item__object'>
                                <img src={box20} alt="" />
                                <p>We alle consume a lot of CO2. That’s why we donate 7.5% of all proceeds to Carbon180, a non-profit organization that focuses on supporting natural and technology-based measures to remove CO2 from the atmosphere.</p>
                            </div>
                        </div>
                        <div className='roadmap__item roadmap__item__70'>
                            <h3>70%</h3>
                            <div className='roadmap__item__object'>
                                <img src={box70} alt="" />
                                <p>Time to celebrate! The first community voting begins and we design our first party together. Where should it take place? Which act should we book? What drinks would you like?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='mobile'>
                    <div className='roadmap__item__wrapper'>
                        <div className='roadmap__item roadmap__item__20'>
                            <h3>20%</h3>
                            <div className='roadmap__item__object'>
                                <img src={box20} alt="" />
                                <p>Our project consumes a lot of CO2. Thats why we donate 7.5% of all income to Carbon180 a non-profit organisation that focuses on supporting natural and technology-based actions to remove CO2 from the atmosphere.</p>
                            </div>
                        </div>
                        <div className='roadmap__item roadmap__item__50'>
                            <h3>50%</h3>
                            <div className='roadmap__item__object'>
                                <img src={box50} alt="" />
                                <p>It's time to give back! 5 NFT holders win a trip for 10 days to anywhere they want.</p>
                            </div>
                        </div>
                        <div className='roadmap__item roadmap__item__70'>
                            <h3>70%</h3>
                            <div className='roadmap__item__object'>
                                <img src={box70} alt="" />
                                <p>Time to party!!! The first community voting starts and we are planning the first party! Where should the party take place? Which act should we book?
                                </p>
                            </div>
                        </div>
                        <div className='roadmap__item roadmap__item__100'>
                            <h3>100%</h3>
                            <div className='roadmap__item__object'>
                                <img src={box100} alt="" />
                                <p>Mint is over. Roadmap 2 will be released! We have enough capital to plan big things and you help us with your votes!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Roadmap;