export const accordionData = [
  {
    id: '1',
    title: 'Who rules Planet Freak?',
    content: "<p>We are a Swiss Marketing & Event Company called Freaky Swep. Knowing that the crypto and NFT market is a growing field we expect a lot. We are experts in the field of Web 3.0 and know how important NFTs are for success in the coming years.</p>"
  },
  {
    id: '2',
    title: 'Why are you doing this?',
    content: "<p>Our goal is to create a community worldwide that can benefit from many different advantages in the digital and real world. We are well connected throughout Europe and can therefore reach our goal in a shorter time, unlike 99% of our competitors. <br> We look forward to connecting with businesses, events and festivals around the world. We want to create added value for our community. <br>This could be free entry, free drinks or discounts on flights and hotels. We love to work with artists all over the world and look forward to have them performing at our partys and events.</p>"
  },
  {
    id: '3',
    title: 'When is the offical launch?',
    content: "<p>The launch is planned for beginning of Q3, 2022.</p>"
  },
  {
    id: '4',
    title: 'How can I get a Freak?',
    content: "<p>Become a freak and you will be able to mint directly from our website by simply connecting your wallet. You will also be able to purchase from our secondary market on the official Planet Freak OpenSea collection.</p>"
  },
  {
    id: '5',
    title: 'How much to mint a Freak?',
    content: "<p>0.1 ETH + gas (price might change depending on the ETH price) We’d love to add value to the whole NFT market with a project based on the quality standards of Switzerland. Don’t miss the chance to be a part of Planet Freak!</p>"
  },
  {
    id: '6',
    title: 'What do I get when I purchase a freak',
    content: "<p>Holders will unlock many members-only benefits and access to exclusive physical goods. You will also have access to the 'members only' section of our website, where you can vote on the direction the project should take. You can also help decide the details of our party there.</p>"
  }
];