import footerLogo from '../assets/images/footer_logo.png';
import boat from '../assets/svg/boat.svg';
import twitter from '../assets/svg/twitter.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactSVG } from 'react-svg';
function Footer() {
    return (
        <>
            <footer className='footer'>
                <div className='footer__image'>
                    <img src={footerLogo} alt="" />
                </div>
                <div className='footer__text'>Copyright © Planet Freak 2022</div>
                <div className='footer__social'>
                    <a href="https://opensea.io/collection/planet-freak-v2" className='footer__social__boat'><ReactSVG src={boat} /></a>
                    <a href="https://twitter.com/PlanetFreakNFT" className='footer__social__twitter'><ReactSVG src={twitter} /></a>
                </div>
            </footer>
        </>
    );
}

export default Footer;