
import PrimaryLogo from "./logo";
import React from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
function Navigation() {
    const [open, setOpen] = React.useState(false);
    const windowWidth = document.documentElement.clientWidth;

    const [docWidth, setDocWidth] = React.useState(windowWidth);
    React.useEffect(() => {
        function updateSize() {
            setDocWidth([windowWidth]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [windowWidth]);

    return (
        <>
            <button
                onClick={() => setOpen(!open)}
                aria-controls="collapsable"
                aria-expanded={open}
                className={`hamburger-lines ${open ? 'expanded' : 'collapsed'}`}
            >
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
            </button>
            <PrimaryLogo className={`mobile-logo ${open ? 'expanded' : 'collapsed'}`} />
            <Collapse in={open} dimension="height">
                <nav className='nav'>
                    {docWidth >= 1025 ?
                        <>
                            <div className='nav__left'>
                                <ul>
                                    <li>
                                        <Link to={{ pathname: "/", hash: "howTo" }}>
                                            How does it work?
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={{
                                                pathname: "/",
                                                hash: "collections"
                                            }}
                                        >
                                            Collections
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={{
                                                pathname: "/",
                                                hash: "mint"
                                            }}
                                        >
                                            Mint it
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <PrimaryLogo className='logo-desktop' />
                            <div className='nav__right'>
                                <ul>
                                    <li>
                                        <Link
                                            to={{
                                                pathname: "/",
                                                hash: "roadmap"
                                            }}
                                        >
                                            Road Map
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={{
                                                pathname: "/",
                                                hash: "faq"
                                            }}
                                        >
                                            FAQ
                                        </Link>
                                    </li>
                                    <li><Link to='/community'>Community</Link></li>
                                </ul>
                            </div>
                        </>
                        :
                        <div className='nav__mobile'>
                            <ul>
                                <li>
                                    <Link to={{ pathname: "/", hash: "howTo" }}>
                                        Hows it work
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            hash: "collections"
                                        }}
                                    >
                                        Collections
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            hash: "mint"
                                        }}
                                    >
                                        Mint it
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            hash: "roadmap"
                                        }}
                                    >
                                        Road Map
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={{
                                            pathname: "/",
                                            hash: "faq"
                                        }}
                                    >
                                        FAQ
                                    </Link>
                                </li>
                                <li><Link to='/community'>Community</Link></li>
                            </ul>
                        </div>
                    }
                </nav>
            </Collapse>
        </>
    );
}

export default Navigation;