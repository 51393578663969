import { forwardRef } from "react";

const ContentBlock = forwardRef((props, ref) => (
    <>
        <div
            ref={ref}
            id={props.id}
            className={`content-block__text-${props.alignment === 'center' ? 'centered' : 'left-aligned'}${props.className ? ` ${props.className}` : ''}`}>
            <h2>{props.heading}</h2>
            <p>{props.text}</p>
            {props.children}
        </div>
    </>
));

export default ContentBlock;