import Accordion from 'react-bootstrap/Accordion';
import { accordionData } from '../utils/accordionContent';
function PFAccordion() {
    return (
        <Accordion>
            <h2 className='accordion__heading'>FAQ</h2>
            {accordionData?.map((accordionItem, index) => {
                return (
                    <Accordion.Item eventKey={accordionItem.id} key={index}>
                        <Accordion.Header>{accordionItem.title}</Accordion.Header>
                        <Accordion.Body>
                            <div dangerouslySetInnerHTML={{ __html: accordionItem.content }} />
                            {/* <p>{accordionItem.content}</p> */}
                        </Accordion.Body>
                    </Accordion.Item>
                );
            })}
        </Accordion>
    )
}

export default PFAccordion;