import Navigation from "./navigation";
function Header() {
    return (
        <header>
            <Navigation />
            <div className='bg-filter' />
        </header>
    );
}

export default Header;