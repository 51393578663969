import primaryLogo from '../assets/images/primary_logo.png'
import { useNavigate } from 'react-router-dom';

function PrimaryLogo(props) {
    let navigate = useNavigate();
    return (
        <div onClick={() => navigate("/")} className={`logo${props.className ? ` ${props.className}` : ''}`}>
            <img src={primaryLogo} alt="Planet Freak Logo" title='Planet Freak' />
        </div>
    );
}

export default PrimaryLogo;