import React from 'react';
import { useLocation } from 'react-router-dom';
// import { ParallaxProvider } from 'react-scroll-parallax';

// Components
import Header from "../components/header";
import ContentBlock from "../components/content-block";
import Collection from "../components/collection";
import Minting from '../components/minting';
import Roadmap from '../components/roadmap';
import PFAccordion from '../components/accordion';
import Section from '../components/section';
import { ImageObject, Butterfly } from "../components/image-object";
import Footer from '../components/footer';
//Images
import imageP1 from '../assets/images/bg-image-p1.jpg';
import imageP2 from '../assets/images/bg-image-p2-2.jpg';
import imageP3 from '../assets/images/bg-image-p3.jpg';
import imageP4 from '../assets/images/bg-image-p4.jpg';
import imageFaq from '../assets/images/sub-bg-image-p5.png';
import imageFooter from '../assets/images/footer-bg.png';
import mainOrangeAnimal from '../assets/images/main_img.png';
import iceSvg from '../assets/svg/ice_mountain.svg';
import springSvg from '../assets/svg/spring_mountain.svg';
import butterfly from '../assets/images/butterfly.png';


function HomePage() {
  document.body.classList.remove('community-page');
  const location = useLocation();
  React.useEffect(() => {
    if (location.hash) {
      console.log("Location hash", location.hash);
      let elem = document.getElementById(location.hash.split("#")[1]);
      elem?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);
  return (
    <div className='content'>
      <Header />
      <Section id={'one'} imgSrc={imageP1}>
        <div className='object'>
          <div className='object-wrapper'>
            <ImageObject name={'winter'} imgSrc={iceSvg} />
            <ImageObject name={'main'} imgSrc={mainOrangeAnimal} />
            <ImageObject name={'spring'} imgSrc={springSvg} />
          </div>
          <ContentBlock
            text={`Planet Freak is a randomly generated collection of 5000 freaks. Our goal is to create a community all over the world. Planet Freak allows you to attend parties and events around the world and become part of the freak elite.`}
            alignment={'center'}
          />
        </div>
      </Section>
      <Section id={'two'} imgSrc={imageP2}>
        <Butterfly name={'butterfly-top'} imgSrc={butterfly} />
        <Butterfly name={'butterfly-middle'} imgSrc={butterfly} />
        <ContentBlock
          text={`Through a community voting system, all events can be voted for. You choose the location, the music and all other essential points of each event.`}
          alignment={'left'}
          heading={'How does it work?'}
          id='howTo'
        >
          <p>All events can be voted for via a community voting system. You determine the location, the music and all other variables of the desired event. <br /> Our goal is to help shape the event and NFT space in the long term and define ourselves as a brand. We want to build a community with NFTs that is unique. We will bring value to all participants on Planet Freak and be more than just an investment.</p>
        </ContentBlock>
      </Section>
      <Collection />
      <Section id={'three'} imgSrc={imageP3}>
        <Minting />
      </Section>
      <Section id={'four'} imgSrc={imageP4}>
        <Roadmap />
      </Section>
      <div className='section__faq-footer'>
        <Section id={'faq'} imgSrc={imageFaq}>
          <PFAccordion />
        </Section>
        <Section id={'footer'} imgSrc={imageFooter}>
          <Footer />
        </Section>
      </div>

    </div>
  );
}

export default HomePage;